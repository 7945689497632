import { Base64Image } from './images';
import { CoordinatePair } from './location';
import { MaterialCode } from './material-codes';
import { MaterialForm } from './material-forms';

export type UploadStatus = 'pending' | 'uploading' | 'uploaded' | 'failed';

/**
 * Activity documents correspond to a particular event that happened.
 * They involve actions (i.e. contributions or withdrawals) performed on lots of material.
 */
export type ActivityDocument = CollectionDocument | FulfillmentDocument | InventoryReceivedDocument | ShipmentDocument;

export type ActivityDocumentType = ActivityDocument['__type'];

interface ResourceWithUploadStatus {
  /** The status of the submission to the OW T&T API */
  uploadStatus: UploadStatus;
}

export interface TrackAndTraceAttachment {
  imageData: Base64Image;

  name: string;
}

export interface Entity {
  id: string;
  name: string;
  /** + and digits only */
  phone: string;
}

export interface RegisteredLocation {
  coordinates: CoordinatePair;
  id: string;
  name: string;
}

/** A uniquely identifiable quantity of material (aka a batch).
 * It can be either:
 *  a) an isolated and unchanging discrete collection of material (e.g. a bag or bale) or
 *  b) a pile or "pot" (e.g. a silo or pile) of material that is repeatedly added to and withdrawn from
 */
export interface Lot extends ResourceWithUploadStatus {
  description?: string;
  materialCode: MaterialCode;
  materialForm: MaterialForm;
  number: string;
}

/** A contribution to or withdrawal from a lot */
export interface LotAdjustment {
  /** Pictures, etc */
  attachments: TrackAndTraceAttachment[];

  /** The unique identifier of the lot being contributed to or withdrawn from */
  lotNumber: string;

  /** The net weight of the material added (positive) or removed (negative) from the lot */
  weight: number;
}

interface BaseActivityDocument extends ResourceWithUploadStatus {
  id?: string;
  attachments: TrackAndTraceAttachment[];
  startDate: Date;
  endDate: Date;
  location: CoordinatePair;
  lotAdjustments: LotAdjustment[];
}

/** Contributes to one or many lots of material through positive adjustments */
export interface CollectionDocument extends BaseActivityDocument {
  __type: 'CollectionDocument';
}

/** Withdraws from one or many lots of material through negative adjustments */
export interface FulfillmentDocument extends BaseActivityDocument {
  __type: 'FulfillmentDocument';
  deliveredToEntityId: string | undefined;
  customerName: string | undefined;
}

/** Contributes to one or many lots of material through positive adjustments */
export interface InventoryReceivedDocument extends BaseActivityDocument {
  __type: 'InventoryReceivedDocument';
  receivedFromEntityId: string | undefined;
  supplierName: string | undefined;
}

export interface ShipmentDocument extends Omit<BaseActivityDocument, 'location'> {
  __type: 'ShipmentDocument';
  origin: CoordinatePair;
  originName: string | null;
  destination: CoordinatePair;
  destinationName: string | null;
}

export const isLot = (obj: any): obj is Lot => {
  return (
    obj &&
    isStringWithNonZeroLength(obj.number) &&
    isStringWithNonZeroLength(obj.materialCode) &&
    isStringWithNonZeroLength(obj.materialForm)
  );
};

const isStringWithNonZeroLength = (obj: any): obj is string => {
  return obj && typeof obj === 'string' && obj.length > 0;
};
