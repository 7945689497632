import { PageChrome } from 'src/components/atoms/PageChrome/PageChrome';
import { EntityCreator } from 'src/components/organisms/EntityCreator/EntityCreator';

export const EntityCreatePage = () => {
  return (
    <PageChrome title="Create Entity">
      <EntityCreator />
    </PageChrome>
  );
};
