import { useState } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { Button } from 'src/components/atoms/Button/Button';
import { Card } from 'src/components/atoms/Card/Card';
import { TextInput } from 'src/components/atoms/TextInput/TextInput';
import { db } from 'src/modules/storage/db';
import { Lot } from 'src/types/tnt-docs';

import './ExistingLotSelector.css';

interface ExistingLotSelectorProps {
  excludeLotNumbers: Set<string> | undefined;
  onLotSelected: (lot: Lot) => void;
  onRequestCancel: () => void;
}

export const ExistingLotSelector: React.FC<ExistingLotSelectorProps> = ({
  excludeLotNumbers,
  onLotSelected,
  onRequestCancel,
}) => {
  const [filterByLotNumberInputValue, setFilterByLotNumberInputValue] = useState<string>('');

  const filterByNameRegex = new RegExp(filterByLotNumberInputValue, 'i');
  const lots = useLiveQuery(
    () =>
      db.lots
        .filter((lot) => !excludeLotNumbers?.has(lot.number) && filterByNameRegex.test(lot.number))
        .limit(20)
        .sortBy('name'),
    [filterByLotNumberInputValue],
  );

  return (
    <div className="space-content vertical">
      <h1>Select Existing Lot</h1>
      <TextInput
        label="Filter By Lot Number"
        onChange={setFilterByLotNumberInputValue}
        value={filterByLotNumberInputValue}
      />
      {lots && lots.length > 0 ? (
        <div>
          <ul className="density-compact space-content vertical ExistingLotSelector_LotList">
            {lots.map((lot) => (
              <LotListItem key={lot.number} lot={lot} onClick={() => onLotSelected(lot)} />
            ))}
          </ul>
        </div>
      ) : filterByLotNumberInputValue ? (
        <p>No lots were found matching the given filter.</p>
      ) : (
        <p>No existing lots. Please go back and create a new lot.</p>
      )}
      <Button className="secondary stretch" onClick={onRequestCancel}>
        Go Back
      </Button>
    </div>
  );
};

interface LotListItemProps {
  lot: Lot;
  onClick?: () => void;
}

const LotListItem: React.FC<LotListItemProps> = ({ lot, onClick }) => {
  return (
    <li className="ExistingLotSelector_LotList_LotListItem">
      <Card className="density-compact horizontal justify-space-between pad-content space-content" onClick={onClick}>
        <span>{lot.number}</span>

        <span>{lot.materialCode}</span>
        <span>{lot.materialForm}</span>
      </Card>
    </li>
  );
};
