import { PageChrome } from '../atoms/PageChrome/PageChrome';
import { LoginWizard } from '../organisms/LoginWizard/LoginWizard';

interface LoggedOutLandingPageProps {
  onLogin: () => void;
}

export const LoggedOutLandingPage: React.FC<LoggedOutLandingPageProps> = ({ onLogin }) => {
  return (
    <PageChrome title="Welcome to Oceanworks Track & Trace">
      <LoginWizard onLogin={onLogin} />
    </PageChrome>
  );
};
