import { InputWrapper, InputWrapperProps } from '../InputWrapper/InputWrapper';

interface DateTimeInputProps extends Omit<InputWrapperProps, 'children'> {
  onChange: (value: Date) => void;
  value: Date | undefined;
}

export const DateTimeInput: React.FC<DateTimeInputProps> = ({ label, onChange, value = new Date(), ...rest }) => {
  const fullYear = value.getFullYear();
  const month = zeroPadIfNecessary(value.getMonth() + 1);
  const dayOfMonth = zeroPadIfNecessary(value.getDate());
  const dateLocal = `${fullYear}-${month}-${dayOfMonth}`;
  const timeLocal = `${zeroPadIfNecessary(value.getHours())}:${zeroPadIfNecessary(value.getMinutes())}`;
  return (
    <InputWrapper label={label}>
      <div className="horizontal justify-space-between space-content">
        <input
          type="date"
          onChange={(e) => {
            const fullDateString = `${e.target.value}T${timeLocal}`;
            const newValue = new Date(fullDateString);
            onChange(newValue);
          }}
          value={dateLocal}
          {...rest}
        />
        <input
          type="time"
          onChange={(e) => {
            const fullDateString = `${dateLocal}T${e.target.value}`;
            const newValue = new Date(fullDateString);
            onChange(newValue);
          }}
          value={timeLocal}
          {...rest}
        />
      </div>
    </InputWrapper>
  );
};

const zeroPadIfNecessary = (value: number) => {
  return value < 10 ? `0${value}` : value;
};
