import { RouteObject } from 'react-router-dom';
import { CollectionEntryPage } from 'src/components/pages/CollectionEntry';
import { EntityCreatePage } from 'src/components/pages/EntityCreatePage';
import { EntityListPage } from 'src/components/pages/EntityListPage';

import { FulfillmentEntryPage } from './components/pages/FulfillmentEntryPage';
import { HomePage } from './components/pages/Home';
import { InventoryReceivedEntryPage } from './components/pages/InventoryReceivedEntryPage';
import { ShipmentEntryPage } from './components/pages/ShipmentEntryPage';
import { SignUpPage } from './components/pages/SignUpPage';

export const UI_ROUTES = {
  home: '/',
  collections: {
    list: '/collections',
    entry: '/collections/entry',
  },
  entities: {
    create: '/entities/new',
    list: '/entities',
  },
  fulfillment: {
    create: '/fulfillment/new',
    list: '/fulfillment',
  },
  inventoryReceived: {
    create: '/inventory-received/new',
    list: '/inventory-received',
  },
  shipment: {
    create: '/shipment/new',
  },
  signIn: '/sign-in',
  signUp: '/sign-up',
};

export const routerRoutes: RouteObject[] = [
  {
    path: UI_ROUTES.home,
    element: <HomePage />,
  },
  {
    path: UI_ROUTES.collections.entry,
    element: <CollectionEntryPage />,
  },
  {
    path: UI_ROUTES.entities.create,
    element: <EntityCreatePage />,
  },
  {
    path: UI_ROUTES.entities.list,
    element: <EntityListPage />,
  },
  {
    path: UI_ROUTES.inventoryReceived.create,
    element: <InventoryReceivedEntryPage />,
  },
  {
    path: UI_ROUTES.fulfillment.create,
    element: <FulfillmentEntryPage />,
  },
  {
    path: UI_ROUTES.shipment.create,
    element: <ShipmentEntryPage />,
  },
  {
    path: UI_ROUTES.signUp,
    element: <SignUpPage />,
  },
];
