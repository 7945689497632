import { InputWrapper, InputWrapperProps } from '../InputWrapper/InputWrapper';

interface TextInputProps
  extends Omit<InputWrapperProps, 'children'>,
    Pick<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  onChange: (value: string) => void;
  name?: string;
  value: string;
}

export const TextInput: React.FC<TextInputProps> = ({ label, onChange, ...rest }) => {
  return (
    <InputWrapper label={label}>
      <input onChange={(e) => onChange(e.target.value)} {...rest} />
    </InputWrapper>
  );
};
