import { useLiveQuery } from 'dexie-react-hooks';
import { db } from 'src/modules/storage/db';

import { ActivityCard } from './_internal/ActivityCard';

export const ActivityList: React.FC = () => {
  const documents = useLiveQuery(() => db.documents.toArray());

  return (
    <div className="light gray-bg grow pad-content space-content vertical">
      {/* <h1>Activity In-Progress</h1> */}
      <h1>Activity</h1>
      {documents && documents.length > 0 ? (
        documents.map((document) => <ActivityCard key={document.id} document={document} />)
      ) : (
        <p>No recorded activity yet!</p>
      )}
    </div>
  );
};
