import { exchangeMagicCodeForJwt } from './api/login';

const LOCAL_STORAGE_KEY = 'jwt';

export function getJwt(): string | null {
  return localStorage.getItem(LOCAL_STORAGE_KEY);
}

export function isLoggedIn(): boolean {
  return getJwt() !== null;
}

export function setJwt(jwt: string): void {
  localStorage.setItem(LOCAL_STORAGE_KEY, jwt);
}

export async function loginWithMagicCode(email: string, magicCode: string) {
  try {
    const jwt = await exchangeMagicCodeForJwt(email, magicCode);
    if (jwt === null) {
      return false;
    }
    setJwt(jwt);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}
