import {
  faCircleDown,
  faCircleRight,
  faHandBackFist,
  faMoneyBill1,
  IconDefinition,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'src/components/atoms/Card/Card';
import { UI_ROUTES } from 'src/routes';
import { ActivityDocument, ActivityDocumentType } from 'src/types/tnt-docs';
import { dateFormatter, numberFormatter } from 'src/utils/formatting';

interface ActivityCardProps {
  document: ActivityDocument;
}

export const ActivityCard: React.FC<ActivityCardProps> = ({ document }) => {
  const weightTotal = document.lotAdjustments.reduce((acc, item) => acc + item.weight, 0);
  return (
    <Card
      className="align-center density-compact horizontal pad-content space-content ActivityCard"
      // childrenWrapperClassName="align-center horizontal space-content"
      // href={UI_ROUTES.collections.list}
    >
      <FontAwesomeIcon icon={CARD_ICON_MAP[document.__type]} size="2x" />
      <div className="vertical ActivityCard_text-container">
        <span>{CARD_TITLE_MAP[document.__type]}</span>
        <span className="font-small">
          {numberFormatter.format(weightTotal)}kg on {dateFormatter.short.format(document.startDate)}
        </span>
      </div>
    </Card>
  );
};

const CARD_ICON_MAP: Record<ActivityDocumentType, IconDefinition> = {
  CollectionDocument: faHandBackFist,
  FulfillmentDocument: faMoneyBill1,
  InventoryReceivedDocument: faCircleDown,
  ShipmentDocument: faCircleRight,
};

const CARD_TITLE_MAP: Record<ActivityDocumentType, string> = {
  CollectionDocument: 'Collection',
  FulfillmentDocument: 'Sale',
  InventoryReceivedDocument: 'Inventory Received',
  ShipmentDocument: 'Shipment',
};
