import { useState } from 'react';
import cx from 'classnames';
import { Button, LinkButton } from 'src/components/atoms/Button/Button';
import { DateTimeInput } from 'src/components/atoms/DateTimeInput/DateTimeInput';
import { EntitySelector } from 'src/components/organisms/EntitySelector/EntitySelector';
import { LotAdjustmentCard } from 'src/components/organisms/LotAdjustmentCard/LotAdjustmentCard';
import { useGeolocation } from 'src/modules/geolocation';
import { submitDocument } from 'src/modules/submission';
import { UI_ROUTES } from 'src/routes';
import { ActivityDocumentType, Entity, LotAdjustment } from 'src/types/tnt-docs';
import { Lot } from 'src/types/tnt-docs';

import { LotAdjustmentEditor } from '../LotAdjustmentEditor/LotAdjustmentEditor';

type DocumentEntryActivityType = Exclude<ActivityDocumentType, 'ShipmentDocument'>;

export interface DocumentEntryProps {
  disableEndDate?: boolean;
  documentType: DocumentEntryActivityType;
  onFinish: () => void;
}

export const DocumentEntry: React.FC<DocumentEntryProps> = ({ disableEndDate, documentType, onFinish }) => {
  const [selectingEntity, setSelectingEntity] = useState(false);
  const [newLots, setNewLots] = useState<Lot[]>([]);
  const [lotAdjustments, setLotAdjustments] = useState<LotAdjustment[]>([]);
  const [entity, setEntity] = useState<Entity>();
  const { position, error } = useGeolocation();
  const [lotAdjustmentIdxBeingEdited, setLotAdjustmentIdxBeingEdited] = useState<number | null>(null);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const disableSubmit = lotAdjustments.length === 0 || !startDate || !endDate;

  const handleSubmit = async () => {
    if (!position) {
      alert('Location not available');
      if (error) alert(error.message);
      return;
    }

    if (!startDate || !endDate) {
      alert('Missing date information');
      return;
    }

    const endDateToSave = disableEndDate ? startDate : endDate;

    // Ensure that we're only creating new lots if they've been referenced by a lot adjustment
    // (i.e. discard any lots that were created but then removed from a lot adjustment)
    const referencedLotNumbers = new Set<string>();
    lotAdjustments.forEach((lotAdjustment) => referencedLotNumbers.add(lotAdjustment.lotNumber));
    const newLotsToCreate = newLots.filter((lot) => referencedLotNumbers.has(lot.number));

    switch (documentType) {
      case 'CollectionDocument':
        await submitDocument(
          {
            __type: 'CollectionDocument',
            attachments: [],
            endDate: endDateToSave,
            startDate,
            lotAdjustments,
            location: position,
            uploadStatus: 'pending',
          },
          newLotsToCreate,
        );
        break;
      case 'FulfillmentDocument':
        if (!entity) {
          alert('No entity selected');
          return;
        }
        await submitDocument(
          {
            __type: 'FulfillmentDocument',
            attachments: [],
            endDate: endDateToSave,
            startDate,
            lotAdjustments,
            location: position,
            deliveredToEntityId: entity.id,
            customerName: entity.name,
            uploadStatus: 'pending',
          },
          newLotsToCreate,
        );
        break;
      case 'InventoryReceivedDocument':
        if (!entity) {
          alert('No entity selected');
          return;
        }
        await submitDocument(
          {
            __type: 'InventoryReceivedDocument',
            attachments: [],
            endDate: endDateToSave,
            startDate,
            lotAdjustments,
            location: position,
            receivedFromEntityId: entity.id,
            supplierName: entity.name,
            uploadStatus: 'pending',
          },
          newLotsToCreate,
        );
        break;
    }
    onFinish();
  };

  if (lotAdjustmentIdxBeingEdited !== null) {
    const selectedLotAdjustment = lotAdjustments[lotAdjustmentIdxBeingEdited];
    return (
      <LotAdjustmentEditor
        documentType={documentType}
        excludeLotNumbersFromSelectOptions={
          new Set(
            lotAdjustments
              // Allow selecting the lot number assigned to this adjustment
              .filter((adjustment) => adjustment !== selectedLotAdjustment)
              .map((adjustment) => adjustment.lotNumber),
          )
        }
        lotAdjustment={selectedLotAdjustment}
        onChange={(lotAdjustment, createdLots) => {
          const newLotAdjustments = [...lotAdjustments];
          newLotAdjustments[lotAdjustmentIdxBeingEdited] = lotAdjustment;
          setLotAdjustments(newLotAdjustments);
          setNewLots([...newLots, ...createdLots]);
          setLotAdjustmentIdxBeingEdited(null);
        }}
        onRequestCancel={() => setLotAdjustmentIdxBeingEdited(null)}
      />
    );
  }

  const entityTerm = ENTITY_TERM_FOR_DOCUMENT_TYPE[documentType];
  if (selectingEntity) {
    return (
      <EntitySelector
        entityTerm={entityTerm}
        onEntitySelected={(entity) => {
          setEntity(entity);
          setSelectingEntity(false);
        }}
        onRequestCancel={() => setSelectingEntity(false)}
      />
    );
  }

  return (
    <>
      <h1>Record {DOCUMENT_TYPE_TO_NAME[documentType]}</h1>
      {FIELD_DEFS[documentType].entity &&
        (entity ? (
          <div className="align-center justify-space-between horizontal">
            <div className="vertical">
              <span>{entityTerm}</span>
              <span>{entity.name}</span>
            </div>
            <Button className="secondary" onClick={() => setSelectingEntity(true)}>
              Change
            </Button>
          </div>
        ) : (
          <Button className="secondary stretch" onClick={() => setSelectingEntity(true)}>
            {'Select ' + entityTerm}
          </Button>
        ))}
      <DateTimeInput label={disableEndDate ? 'Date' : 'Start Date'} value={startDate} onChange={setStartDate} />
      {!disableEndDate && <DateTimeInput label="End Date" value={endDate} onChange={setEndDate} />}
      <h2>Material Lots</h2>
      {lotAdjustments.map((adjustment, idx) => (
        <LotAdjustmentCard
          key={adjustment.lotNumber}
          lotAdjustment={adjustment}
          onClick={() => setLotAdjustmentIdxBeingEdited(idx)}
        />
      ))}
      <Button className="stretch" onClick={() => setLotAdjustmentIdxBeingEdited(lotAdjustments.length)}>
        Add Material Lot
      </Button>
      <Button className="creative stretch" disabled={disableSubmit} onClick={handleSubmit}>
        Submit
      </Button>
      <LinkButton
        className={cx(lotAdjustments.length > 0 ? 'destructive' : 'secondary', 'stretch')}
        href={UI_ROUTES.home}
      >
        Cancel
      </LinkButton>
    </>
  );
};

const FIELD_DEFS: Record<DocumentEntryActivityType, { entity: boolean }> = {
  CollectionDocument: {
    entity: false,
  },
  FulfillmentDocument: {
    entity: true,
  },
  InventoryReceivedDocument: {
    entity: true,
  },
};

const DOCUMENT_TYPE_TO_NAME: Record<DocumentEntryActivityType, string> = {
  CollectionDocument: 'Collection',
  FulfillmentDocument: 'Fulfillment',
  InventoryReceivedDocument: 'Inventory Received',
};

const ENTITY_TERM_FOR_DOCUMENT_TYPE: Record<DocumentEntryActivityType, string> = {
  CollectionDocument: '',
  FulfillmentDocument: 'Buyer',
  InventoryReceivedDocument: 'Supplier',
};
