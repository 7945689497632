import { useState } from 'react';
import { Button } from 'src/components/atoms/Button/Button';
import { CameraTrigger } from 'src/components/atoms/CameraTrigger/CameraTrigger';
import { ImageList } from 'src/components/atoms/ImageList/ImageList';
import { TextInput } from 'src/components/atoms/TextInput/TextInput';
import { LotSelector, NewOrExistingChoice } from 'src/components/organisms/LotSelector/LotSelector';
import { ActivityDocumentType, Lot, LotAdjustment } from 'src/types/tnt-docs';

interface LotAdjustmentEditorProps {
  documentType: ActivityDocumentType;
  excludeLotNumbersFromSelectOptions?: Set<string>;
  lotAdjustment: LotAdjustment | undefined;
  onChange: (lotAdjustment: LotAdjustment, newLots: Lot[]) => void;
  onRequestCancel: () => void;
}

type State = 'selecting-lot' | 'editing-other-adjustment-values';

export const LotAdjustmentEditor: React.FC<LotAdjustmentEditorProps> = ({
  documentType,
  excludeLotNumbersFromSelectOptions,
  lotAdjustment: lotAdjustmentProp,
  onChange,
  onRequestCancel,
}) => {
  const [state, setState] = useState<State>('selecting-lot');
  const [lotAdjustment, setLotAdjustment] = useState<Partial<LotAdjustment>>(lotAdjustmentProp || { attachments: [] });
  const [newLots, setNewLots] = useState<Lot[]>([]);

  const lotAdjustmentIsComplete = lotAdjustment.lotNumber && lotAdjustment.weight;

  switch (state) {
    case 'selecting-lot':
      return (
        <LotSelector
          excludeLotNumbers={excludeLotNumbersFromSelectOptions}
          onRequestCancel={onRequestCancel}
          onSubmit={(lot, isNewlyCreated) => {
            if (isNewlyCreated) {
              setNewLots([...newLots, lot]);
            }
            setLotAdjustment({ ...lotAdjustment, lotNumber: lot.number });
            setState('editing-other-adjustment-values');
          }}
          restrictToNewOrExisting={LOT_CREATION_OR_SELECTION_RESTRICTION[documentType]}
        />
      );
    case 'editing-other-adjustment-values':
      const images = lotAdjustment.attachments?.map((a) => a.imageData) || [];
      return (
        <div className="space-content vertical">
          <TextInput
            label="Weight (kg)"
            onChange={(weight) => setLotAdjustment({ ...lotAdjustment, weight: Number.parseFloat(weight) })}
            value={lotAdjustment.weight?.toString() || ''}
          />
          <CameraTrigger
            onCapture={(image) =>
              setLotAdjustment({
                ...lotAdjustment,
                attachments: [
                  ...lotAdjustment.attachments!,
                  {
                    imageData: image,
                    name: image.name,
                  },
                ],
              })
            }
          />
          {images.length > 0 && (
            <ImageList
              images={images}
              onRemove={(idx) => {
                const newAttachments = [...lotAdjustment.attachments!];
                newAttachments.splice(idx, 1);
                setLotAdjustment({ ...lotAdjustment, attachments: newAttachments });
              }}
            />
          )}
          <Button
            className="creative stretch"
            disabled={!lotAdjustmentIsComplete}
            onClick={() => onChange(lotAdjustment as LotAdjustment, newLots)}
          >
            {lotAdjustmentProp ? 'Save' : 'Add'}
          </Button>
        </div>
      );
  }
};

const LOT_CREATION_OR_SELECTION_RESTRICTION: Record<ActivityDocumentType, NewOrExistingChoice | undefined> = {
  CollectionDocument: undefined, // 'create-new',
  FulfillmentDocument: 'select-existing',
  InventoryReceivedDocument: undefined, // 'create-new',
  ShipmentDocument: 'select-existing',
};
