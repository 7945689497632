/**
 * Resizes an image to the given max width and height
 * @param base64Image the base64 image to resize
 * @param maxWidth the max width of the image
 * @param maxHeight the max height of the image
 * @param quality the JPG compression quality (0-1) [default: 0.5]
 */
export const resizeBase64Image = (
  base64Image: string,
  maxWidth: number,
  maxHeight: number,
  quality = 0.5,
): Promise<string> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      // Prepare the necessary elements
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (!context) return reject('Could not get canvas context');

      // Do the resizing
      let { width, height } = img;
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }
      canvas.width = width;
      canvas.height = height;
      context.drawImage(img, 0, 0, width, height);

      // Return the result
      const dataUrl = canvas.toDataURL('image/jpeg', quality);
      resolve(dataUrl);
    };
    img.src = base64Image;
  });
