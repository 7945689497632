import { useEffect, useState } from 'react';
import { CoordinatePair } from 'src/types/location';

const positionOptions: PositionOptions = {
  enableHighAccuracy: true,
  maximumAge: 1000 * 60 * 5, // 5 minutes
};

export const useGeolocation = () => {
  const [position, setPosition] = useState<CoordinatePair>();
  const [error, setError] = useState<GeolocationPositionError>();

  useEffect(() => {
    if (!('geolocation' in navigator)) {
      setError({
        code: 0,
        message: 'Geolocation is not supported',
        POSITION_UNAVAILABLE: 1,
        PERMISSION_DENIED: 0,
        TIMEOUT: 0,
      });
    }

    const positionReceivedCallback: PositionCallback = ({ coords }) => {
      setPosition({
        latitude: coords.latitude,
        longitude: coords.longitude,
      });
    };

    const positionErrorCallback: PositionErrorCallback = (error) => {
      setError(error);
    };

    navigator.geolocation.getCurrentPosition(positionReceivedCallback, positionErrorCallback, positionOptions);

    const watcher = navigator.geolocation.watchPosition(
      positionReceivedCallback,
      positionErrorCallback,
      positionOptions,
    );

    return () => navigator.geolocation.clearWatch(watcher);
  }, []);

  return { error, position };
};
