import { LinkButtonCard } from 'src/components/atoms/Card/Card';
import { LotAdjustment } from 'src/types/tnt-docs';

import './LotAdjustmentCard.css';

interface LotAdjustmentCardProps {
  lotAdjustment: LotAdjustment;
  onClick: () => void;
}

export const LotAdjustmentCard: React.FC<LotAdjustmentCardProps> = ({ lotAdjustment, onClick }) => {
  return (
    <LinkButtonCard
      className="density-compact pad-content LotAdjustmentCard"
      childrenWrapperClassName="align-center horizontal space-content"
      onClick={onClick}
    >
      <span>{lotAdjustment.lotNumber}</span>
      <span>{lotAdjustment.weight} kg</span>
    </LinkButtonCard>
  );
};
