import { Select, SelectOption, SelectProps } from 'src/components/atoms/Select/Select';
import { MaterialForm } from 'src/types/material-forms';

export type MaterialFormSelectProps = Omit<SelectProps<MaterialForm>, 'label' | 'options'>;

const OPTIONS: SelectOption<MaterialForm>[] = [
  { label: 'Chip', value: 'CHIP' },
  { label: 'Finished Part', value: 'FINISHED-PART' },
  { label: 'Flake', value: 'FLAKE' },
  { label: 'Molded Part', value: 'MOLDED-PART' },
  { label: 'Pellet', value: 'PELLET' },
  { label: 'Raw Bottle', value: 'RAW-BOTTLE' },
  { label: 'Raw Film', value: 'RAW-FILM' },
  { label: 'Raw Mixed', value: 'RAW-MIXED' },
  { label: 'Raw Net', value: 'RAW-NET' },
];

export const MaterialFormSelect: React.FC<MaterialFormSelectProps> = (props) => {
  return <Select<MaterialForm> label="Material Form" options={OPTIONS} {...props} />;
};
