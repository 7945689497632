import { Link } from 'react-router-dom';
import cx from 'classnames';

import './Button.css';

interface BaseButtonProps {
  className?: string;
  children: string;
}

export interface ButtonProps extends BaseButtonProps {
  disabled?: boolean;
  onClick: () => void;
}

export const Button: React.FC<ButtonProps> = ({ className, children, disabled, onClick }) => {
  return (
    <button className={cx('Button', className)} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  );
};

interface LinkButtonProps extends BaseButtonProps {
  href: string;
}

export const LinkButton: React.FC<LinkButtonProps> = ({ className, children, href }) => {
  return (
    <Link className={cx('Button LinkButton', className)} to={href} tabIndex={0}>
      {children}
    </Link>
  );
};
