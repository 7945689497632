import { useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { LoggedOutLandingPage } from './components/pages/LoggedOutLandingPage';
import { isLoggedIn } from './modules/account';
import { routerRoutes } from './routes';

import 'src/styles/App.css';

const router = createBrowserRouter(routerRoutes);

function App() {
  const [loggedIn, setLoggedIn] = useState(isLoggedIn());
  if (!loggedIn) {
    return <LoggedOutLandingPage onLogin={() => setLoggedIn(true)} />;
  }

  return <RouterProvider router={router} />;
}

export default App;
