import { useState } from 'react';
import cx from 'classnames';
import { Button, LinkButton } from 'src/components/atoms/Button/Button';
import { CameraTrigger } from 'src/components/atoms/CameraTrigger/CameraTrigger';
import { DateTimeInput } from 'src/components/atoms/DateTimeInput/DateTimeInput';
import { ImageList } from 'src/components/atoms/ImageList/ImageList';
import { LocationSelector } from 'src/components/organisms/LocationSelector/LocationSelector';
import { LotAdjustmentCard } from 'src/components/organisms/LotAdjustmentCard/LotAdjustmentCard';
import { LotAdjustmentEditor } from 'src/components/organisms/LotAdjustmentEditor/LotAdjustmentEditor';
import { submitDocument } from 'src/modules/submission';
import { UI_ROUTES } from 'src/routes';
import { LotAdjustment, RegisteredLocation, TrackAndTraceAttachment } from 'src/types/tnt-docs';

import { ShipmentLocationSelector } from './_internal/ShipmentLocationSelector';

export interface ShipmentEntryProps {
  onFinish: () => void;
}

type LocationSelectionState = 'origin' | 'destination' | null;

export const ShipmentEntry: React.FC<ShipmentEntryProps> = ({ onFinish }) => {
  const [selectingLocation, setSelectingLocation] = useState<LocationSelectionState>(null);
  const [origin, setOrigin] = useState<RegisteredLocation>();
  const [destination, setDestination] = useState<RegisteredLocation>();
  const [documentAttachments, setDocumentAttachments] = useState<TrackAndTraceAttachment[]>([]);
  const [lotAdjustments, setLotAdjustments] = useState<LotAdjustment[]>([]);
  const [lotAdjustmentIdxBeingEdited, setLotAdjustmentIdxBeingEdited] = useState<number | null>(null);

  const [departureDate, setDepartureDate] = useState(new Date());
  const [arrivalDate, setArrivalDate] = useState(new Date());

  const handleSubmit = async () => {
    if (!origin || !destination || !departureDate || !arrivalDate) {
      alert('Missing information');
      return;
    }
    await submitDocument(
      {
        __type: 'ShipmentDocument',
        origin: origin.coordinates,
        destination: destination.coordinates,
        attachments: [],
        destinationName: destination.name,
        originName: origin.name,
        endDate: arrivalDate,
        startDate: departureDate,
        uploadStatus: 'pending',
        lotAdjustments,
      },
      [],
    );
    onFinish();
  };

  if (selectingLocation) {
    return (
      <LocationSelector
        excludeLocationIdFromOptions={selectingLocation === 'origin' ? destination?.id : origin?.id}
        onLocationSelected={(location) => {
          switch (selectingLocation) {
            case 'origin':
              setOrigin(location);
              break;
            case 'destination':
              setDestination(location);
              break;
          }
          setSelectingLocation(null);
        }}
        onRequestCancel={() => setSelectingLocation(null)}
      />
    );
  }

  if (lotAdjustmentIdxBeingEdited !== null) {
    const selectedLotAdjustment = lotAdjustments[lotAdjustmentIdxBeingEdited];
    return (
      <LotAdjustmentEditor
        documentType="ShipmentDocument"
        excludeLotNumbersFromSelectOptions={
          new Set(
            lotAdjustments
              // Allow selecting the lot number assigned to this adjustment
              .filter((adjustment) => adjustment !== selectedLotAdjustment)
              .map((adjustment) => adjustment.lotNumber),
          )
        }
        lotAdjustment={selectedLotAdjustment}
        onChange={(lotAdjustment) => {
          const newLotAdjustments = [...lotAdjustments];
          newLotAdjustments[lotAdjustmentIdxBeingEdited] = lotAdjustment;
          setLotAdjustments(newLotAdjustments);
          setLotAdjustmentIdxBeingEdited(null);
        }}
        onRequestCancel={() => setLotAdjustmentIdxBeingEdited(null)}
      />
    );
  }

  return (
    <>
      <h1>Record Shipment</h1>
      <ShipmentLocationSelector
        currentValue={origin}
        onRequestSelect={() => setSelectingLocation('origin')}
        originOrDestination="Origin"
      />
      <DateTimeInput label="Departure" onChange={setDepartureDate} value={departureDate} />
      <ShipmentLocationSelector
        currentValue={destination}
        onRequestSelect={() => setSelectingLocation('destination')}
        originOrDestination="Destination"
      />
      <DateTimeInput label="Arrival" onChange={setArrivalDate} value={arrivalDate} />
      <h2>Material Lots</h2>
      {lotAdjustments.map((adjustment, idx) => (
        <LotAdjustmentCard
          key={adjustment.lotNumber}
          lotAdjustment={adjustment}
          onClick={() => setLotAdjustmentIdxBeingEdited(idx)}
        />
      ))}
      <Button className="stretch" onClick={() => setLotAdjustmentIdxBeingEdited(lotAdjustments.length)}>
        Add Material Lot
      </Button>
      <h2>Shipment Images</h2>
      {documentAttachments.length > 0 ? (
        <ImageList
          images={documentAttachments.map((attachment) => attachment.imageData)}
          onRemove={(idx) => {
            const newAttachments = [...documentAttachments];
            newAttachments.splice(idx, 1);
            setDocumentAttachments(newAttachments);
          }}
        />
      ) : (
        <p>Add pictures of the entire shipment (i.e. multiple lots) here.</p>
      )}
      <CameraTrigger
        onCapture={(imageData) => setDocumentAttachments([...documentAttachments, { imageData, name: imageData.name }])}
      />
      <Button className="creative stretch" disabled={lotAdjustments.length === 0} onClick={handleSubmit}>
        Submit
      </Button>
      <LinkButton
        className={cx(lotAdjustments.length > 0 ? 'destructive' : 'secondary', 'stretch')}
        href={UI_ROUTES.home}
      >
        Cancel
      </LinkButton>
    </>
  );
};
