import { LinkButton } from '../atoms/Button/Button';
import { EntityCreator } from '../organisms/EntityCreator/EntityCreator';

export const SignUpPage: React.FC = () => {
  return (
    <div className="page dark pad-content space-content vertically-center-content">
      <h1 className="title">Sign Up</h1>
      <EntityCreator />
      <p>Already have an account?</p>
      <LinkButton className="blue-flats" href="/sign-in">
        Sign In
      </LinkButton>
    </div>
  );
};
