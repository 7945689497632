import { useState } from 'react';
import { Button } from 'src/components/atoms/Button/Button';
import { TextInput } from 'src/components/atoms/TextInput/TextInput';
import { loginWithMagicCode } from 'src/modules/account';
import { requestMagicCodeEmail } from 'src/modules/api/login';

interface LoginWizardProps {
  onLogin: () => void;
}

export const LoginWizard: React.FC<LoginWizardProps> = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [verifyingCode, setVerifyingCode] = useState(false);

  const handleEmailEntered = async (email: string) => {
    const requestSentSuccessfully = await requestMagicCodeEmail(email);
    if (!requestSentSuccessfully) {
      alert('Failed to send magic code email. Please try again later.');
      return;
    }
    setEmail(email);
  };

  const handleCodeEntered = async (code: string) => {
    setVerifyingCode(true);
    const loggedInSuccessfully = await loginWithMagicCode(email, code);
    if (loggedInSuccessfully) {
      onLogin();
    } else {
      alert('Login failed. Please check your email and magic code.');
    }
    setVerifyingCode(false);
  };

  return (
    <div className="dark pad-content space-content vertical">
      <h1>Welcome to Oceanworks&reg; Track &amp; Trace Mobile</h1>
      {!email ? (
        <EnterEmail onEmailEntered={handleEmailEntered} />
      ) : (
        <EnterCode
          onCodeEntered={handleCodeEntered}
          onRequestReenterEmail={() => {
            setEmail('');
          }}
          verifyingCode={verifyingCode}
        />
      )}
    </div>
  );
};

interface EnterEmailProps {
  onEmailEntered: (email: string) => void;
}

const EnterEmail: React.FC<EnterEmailProps> = ({ onEmailEntered }) => {
  const [email, setEmail] = useState('');
  return (
    <>
      <p>First, enter your email address to get a one-time login code.</p>
      <TextInput label="Email" onChange={setEmail} type="email" value={email} />
      <Button className="blue-flats stretch" disabled={email.length === 0} onClick={() => onEmailEntered(email)}>
        Email Me a Code
      </Button>
    </>
  );
};

interface EnterCodeProps {
  onCodeEntered: (code: string) => void;
  onRequestReenterEmail: () => void;
  verifyingCode: boolean;
}

const EnterCode: React.FC<EnterCodeProps> = ({ onCodeEntered, onRequestReenterEmail, verifyingCode }) => {
  const [code, setCode] = useState('');
  return (
    <>
      <p>Next, enter the code you received via email.</p>
      <TextInput label="Code" onChange={setCode} value={code} />
      <Button
        className="blue-flats stretch"
        disabled={code.length === 0 || verifyingCode}
        onClick={() => onCodeEntered(code)}
      >
        {verifyingCode ? 'Logging in...' : 'Login'}
      </Button>
      <Button className="stretch" onClick={onRequestReenterEmail}>
        Re-Enter Email
      </Button>
    </>
  );
};
