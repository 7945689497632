import { db } from 'src/modules/storage/db';
import { ActivityDocument, Lot } from 'src/types/tnt-docs';

/**
 * Saves all components of a document along with any new lots to the local IndexedDB database on the device.
 * NOTE: This function does not check the validity of any lot references in the activity document.
 */
export const submitDocument = async (activityDocument: ActivityDocument, newLots: Lot[]) => {
  switch (activityDocument.__type) {
    case 'CollectionDocument':
      db.documents.add({
        ...activityDocument,
        id: 'collection-' + new Date().toISOString(),
      });
      break;

    case 'FulfillmentDocument':
      db.documents.add({
        ...activityDocument,
        id: 'fulfillment-' + new Date().toISOString(),
      });
      break;

    case 'InventoryReceivedDocument':
      db.documents.add({
        ...activityDocument,
        id: 'inventory-received-' + new Date().toISOString(),
      });
      break;

    case 'ShipmentDocument':
      db.documents.add({
        ...activityDocument,
        id: 'shipment-' + new Date().toISOString(),
      });
      break;

    default:
      throw new Error('Unsupported document type');
  }

  // Save any new lots to the database
  for (const lot of newLots) {
    await db.lots.add(lot);
  }
};
