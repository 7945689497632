import { useNavigate } from 'react-router-dom';
import { PageChrome } from 'src/components/atoms/PageChrome/PageChrome';
import { DocumentEntry } from 'src/components/organisms/DocumentEntry/DocumentEntry';
import { UI_ROUTES } from 'src/routes';

export const CollectionEntryPage = () => {
  const navigate = useNavigate();
  return (
    <PageChrome title="Record Collection">
      <div className="white-bg light grow pad-content space-content vertical">
        <DocumentEntry documentType="CollectionDocument" onFinish={() => navigate(UI_ROUTES.home)} />
      </div>
    </PageChrome>
  );
};
