import { useRef } from 'react';
import { Button } from 'src/components/atoms/Button/Button';
import { Base64Image } from 'src/types/images';
import { resizeBase64Image } from 'src/utils/images';

import './CameraTrigger.css';

export interface CameraProps {
  /**
   * The name of the image once taken (excluding the extension).
   *
   * Defaults to the file name or a random string if undefined.
   */
  imageName?: string;

  /** @default 1500 */
  maxHeight?: number;

  /** @default 1500 */
  maxWidth?: number;

  onCapture: (image: Base64Image) => void;
}

export const CameraTrigger = ({ imageName, maxHeight = 1500, maxWidth = 1500, onCapture }: CameraProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleCapture: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const file = target.files?.[0];
    if (!file) return;
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const data = await resizeBase64Image(fileReader.result as string, maxWidth, maxHeight);
      onCapture({
        data,
        mimeType: file.type,
        name: imageName ? imageName + '.jpg' : file.name || Math.random().toString(36).substring(7) + '.jpg',
      });
    };
    fileReader.readAsDataURL(file);
  };

  return (
    <>
      <Button className="secondary stretch" onClick={() => inputRef.current?.click()}>
        Take Picture
      </Button>
      <input className="CameraTrigger_input" ref={inputRef} type="file" accept="image/*" onChange={handleCapture} />
    </>
  );
};
