import Dexie, { Table } from 'dexie';
import { ActivityDocument, Entity, Lot, RegisteredLocation } from 'src/types/tnt-docs';

type DocumentKey = string;

export class MySubclassedDexie extends Dexie {
  documents: Table<ActivityDocument, DocumentKey>;
  entities: Table<Entity, DocumentKey>;
  locations: Table<RegisteredLocation, DocumentKey>;
  lots: Table<Lot, DocumentKey>;

  constructor() {
    super('ow-tnt');
    this.version(7).stores(
      Object.entries(storeDefinitions).reduce((stores, [storeName, { primaryKey, otherIndexedKeys }]) => {
        let store = `++${primaryKey}`;
        if (otherIndexedKeys && otherIndexedKeys.length > 0) {
          store += `, ${otherIndexedKeys.join(', ')}`;
        }
        stores[storeName as StoreName] = store;
        return stores;
      }, {} as Record<StoreName, string>),
    );
    this.documents = this.table('documents');
    this.entities = this.table('entities');
    this.locations = this.table('locations');
    this.lots = this.table('lots');
  }
}

type StoreName = 'documents' | 'entities' | 'locations' | 'lots';

interface StoreDefinition {
  primaryKey: string;
  otherIndexedKeys?: string[];
}

const storeDefinitions: Record<StoreName, StoreDefinition> = {
  documents: {
    primaryKey: 'id',
    otherIndexedKeys: ['__type', 'startDate', 'endDate', 'deliveredToEntityId', 'receivedFromEntityId'],
  },
  entities: {
    primaryKey: 'id',
    otherIndexedKeys: ['name', 'phone'],
  },
  locations: {
    primaryKey: 'id',
    otherIndexedKeys: ['name'],
  },
  lots: {
    primaryKey: 'number',
    otherIndexedKeys: ['materialCode', 'materialForm'],
  },
};

export const db = new MySubclassedDexie();
