import { useState } from 'react';
import { Lot } from 'src/types/tnt-docs';

import { ExistingLotSelector } from './_internal/ExistingLotSelector/ExistingLotSelector';
import { LotCreator } from './_internal/LotCreator';
import { NewOrExistingLotPrompt } from './_internal/NewOrExistingLotPrompt';
import { NewOrExistingChoice } from './_internal/types';

export type { NewOrExistingChoice };

interface LotEntryProps {
  excludeLotNumbers?: Set<string>;
  onRequestCancel: () => void;
  onSubmit: (lot: Lot, isNewlyCreated: boolean) => void;
  restrictToNewOrExisting?: NewOrExistingChoice;
}

export const LotSelector = ({
  excludeLotNumbers,
  onRequestCancel,
  onSubmit,
  restrictToNewOrExisting,
}: LotEntryProps) => {
  const [state, setState] = useState<NewOrExistingChoice | null>(restrictToNewOrExisting ?? null);

  switch (state) {
    case null:
      return <NewOrExistingLotPrompt onOptionSelect={setState} onRequestCancel={onRequestCancel} />;

    case 'create-new':
      return (
        <LotCreator
          onRequestCancel={() => (restrictToNewOrExisting ? onRequestCancel() : setState(null))}
          onSubmit={(lot) => onSubmit(lot, true)}
        />
      );

    case 'select-existing':
      return (
        <ExistingLotSelector
          excludeLotNumbers={excludeLotNumbers}
          onLotSelected={(lot) => onSubmit(lot, false)}
          onRequestCancel={() => (restrictToNewOrExisting ? onRequestCancel() : setState(null))}
        />
      );
  }
};
