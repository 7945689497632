import { useState } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { Button } from 'src/components/atoms/Button/Button';
import { Card } from 'src/components/atoms/Card/Card';
import { TextInput } from 'src/components/atoms/TextInput/TextInput';
import { db } from 'src/modules/storage/db';
import { RegisteredLocation } from 'src/types/tnt-docs';

import { LocationCreator } from '../LocationCreator/LocationCreator';

import './LocationSelector.css';

export interface LocationSelectorProps {
  excludeLocationIdFromOptions?: string;
  onLocationSelected: (location: RegisteredLocation, isNewlyCreated: boolean) => void;
  onRequestCancel: () => void;
}

export const LocationSelector: React.FC<LocationSelectorProps> = ({
  excludeLocationIdFromOptions,
  onLocationSelected,
  onRequestCancel,
}) => {
  const [creatingLocation, setCreatingLocation] = useState<boolean>(false);
  const [filterByNameInputValue, setFilterByNameInputValue] = useState<string>('');

  const filterByNameRegex = new RegExp(filterByNameInputValue, 'i');
  const locations = useLiveQuery(
    () =>
      db.locations
        .filter((location) => location.id !== excludeLocationIdFromOptions && filterByNameRegex.test(location.name))
        .limit(20)
        .sortBy('name'),
    [filterByNameInputValue],
  );

  if (creatingLocation) {
    return (
      <LocationCreator
        onLocationCreated={(location) => onLocationSelected(location, true)}
        onRequestCancel={() => setCreatingLocation(false)}
      />
    );
  }

  return (
    <div className="space-content vertical">
      <h1>Select Location</h1>
      {locations && locations.length > 0 && (
        <>
          <TextInput label="Filter By Name" onChange={setFilterByNameInputValue} value={filterByNameInputValue} />
          <div>
            <ul className="density-compact space-content vertical LocationSelector_LocationList">
              {locations.map((location) => (
                <LocationListItem
                  key={location.id}
                  location={location}
                  onClick={() => onLocationSelected(location, false)}
                />
              ))}
            </ul>
          </div>
        </>
      )}
      <Button className="secondary stretch" onClick={() => setCreatingLocation(true)}>
        Create New Location
      </Button>
      <Button className="stretch" onClick={onRequestCancel}>
        Go Back
      </Button>
    </div>
  );
};

interface LocationListItemProps {
  location: RegisteredLocation;
  onClick?: () => void;
}

const LocationListItem: React.FC<LocationListItemProps> = ({ location, onClick }) => {
  return (
    <li className="LocationSelector_LocationList_LocationListItem">
      <Card className="density-compact horizontal justify-space-between pad-content space-content" onClick={onClick}>
        <span>{location.name}</span>
      </Card>
    </li>
  );
};
