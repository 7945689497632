import { InputWrapper, InputWrapperProps } from 'src/components/atoms/InputWrapper/InputWrapper';

export interface SelectProps<T = string> extends Omit<InputWrapperProps, 'children'> {
  clearable?: boolean;
  onChange: (value: T | undefined) => void;
  options: SelectOption<T>[];
  value: T | undefined;
}

export interface SelectOption<T> {
  label: string;
  value: T;
}

export function Select<T extends string = string>({ clearable, label, onChange, options, value }: SelectProps<T>) {
  return (
    <InputWrapper label={label}>
      <select value={value || ''} onChange={(e) => onChange((e.target.value as T) || undefined)}>
        {clearable && <option value=""></option>}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </InputWrapper>
  );
}
