import cx from 'classnames';

import './ViewChevron.css';

interface ViewChevronProps {
  className?: string;
}

export const ViewChevron: React.FC<ViewChevronProps> = ({ className }) => {
  return (
    <svg
      className={cx('no-shrink ViewChevron ', className)}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      height={36}
      width={36}
      viewBox="0 0 36 36"
      xmlSpace="preserve"
    >
      <path d="M18,0.8L18,0.8c9.5,0,17.2,7.7,17.2,17.2l0,0c0,9.5-7.7,17.2-17.2,17.2l0,0C8.5,35.2,0.8,27.5,0.8,18l0,0 C0.8,8.5,8.5,0.8,18,0.8z" />
      <polyline points="16,14.1 20,18 16,21.9 " />
    </svg>
  );
};
