import { Link } from 'react-router-dom';
import { PageChrome } from 'src/components/atoms/PageChrome/PageChrome';
import { EntityList } from 'src/components/organisms/EntityList/EntityList';
import { UI_ROUTES } from 'src/routes';

export const EntityListPage = () => {
  return (
    <PageChrome title="Entities">
      <Link to={UI_ROUTES.entities.create}>Create Entity</Link>
      <EntityList />
    </PageChrome>
  );
};
