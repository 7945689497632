import { Button } from 'src/components/atoms/Button/Button';
import { RegisteredLocation } from 'src/types/tnt-docs';

interface ShipmentLocationSelectorProps {
  currentValue: RegisteredLocation | undefined;
  onRequestSelect: () => void;
  originOrDestination: 'Origin' | 'Destination';
}

export const ShipmentLocationSelector: React.FC<ShipmentLocationSelectorProps> = ({
  currentValue,
  onRequestSelect,
  originOrDestination,
}) => {
  if (currentValue) {
    return (
      <div className="align-center justify-space-between horizontal">
        <div className="vertical">
          <span>{originOrDestination}</span>
          <span>{currentValue.name}</span>
        </div>
        <Button className="secondary" onClick={onRequestSelect}>
          Change
        </Button>
      </div>
    );
  }

  return (
    <Button className="secondary stretch" onClick={onRequestSelect}>
      {'Select ' + originOrDestination}
    </Button>
  );
};
