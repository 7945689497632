import cx from 'classnames';
import { LinkButton } from 'src/components/atoms/Button/Button';
import { UI_ROUTES } from 'src/routes';

import './RecordActivityPrompt.css';

interface RecordActivityPromptProps {
  className?: string;
}

const buttonClasses = 'blue-flats stretch';

export const RecordActivityPrompt: React.FC<RecordActivityPromptProps> = ({ className }) => {
  return (
    <div className={cx('dark pad-content space-content RecordActivityPrompt', className)}>
      <h1>Record New Activity</h1>
      <LinkButton className={buttonClasses} href={UI_ROUTES.collections.entry}>
        Collection
      </LinkButton>
      <LinkButton className={buttonClasses} href={UI_ROUTES.inventoryReceived.create}>
        Inventory Received
      </LinkButton>
      <LinkButton className={buttonClasses} href={UI_ROUTES.shipment.create}>
        Shipment
      </LinkButton>
      <LinkButton className={buttonClasses} href={UI_ROUTES.fulfillment.create}>
        Sale
      </LinkButton>
    </div>
  );
};
