import { Link } from 'react-router-dom';
import cx from 'classnames';

import { ViewChevron } from '../ViewChevron/ViewChevron';

import './Card.css';

interface CardProps {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
  tabIndex?: number;
}

export const Card: React.FC<CardProps> = ({ children, className, onClick, tabIndex }) => {
  return (
    <div className={cx('Card ', onClick && 'LinkCard', className)} onClick={onClick} tabIndex={tabIndex}>
      {children}
    </div>
  );
};

interface LinkCardProps extends CardProps {
  childrenWrapperClassName?: string;
  href: string;
}

interface LinkButtonCardProps extends CardProps {
  childrenWrapperClassName?: string;
  onClick: () => void;
}

export const LinkCard: React.FC<LinkCardProps> = ({ children, childrenWrapperClassName, className, href }) => {
  return (
    <Link className={cx('pad-content Card LinkCard', className)} to={href} tabIndex={0}>
      <div className={cx('LinkCard_children-container', childrenWrapperClassName)}>{children}</div>
      <ViewChevron />
    </Link>
  );
};

export const LinkButtonCard: React.FC<LinkButtonCardProps> = ({
  children,
  childrenWrapperClassName,
  className,
  onClick,
}) => {
  return (
    <Card className={cx('Card LinkCard LinkButtonCard', className)} onClick={onClick} tabIndex={0}>
      <div className={cx('LinkCard_children-container', childrenWrapperClassName)}>{children}</div>
      <ViewChevron />
    </Card>
  );
};
