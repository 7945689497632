import { makePostRequest } from './common';

/**
 * Makes a request for the API to send a magic code to the given email address.
 * @param email the email address corresponding to the account the user would like to sign in with
 * @returns whether the request was successful
 */
export async function requestMagicCodeEmail(email: string) {
  try {
    const response = await makePostRequest('/auth/trigger-mobile-login-email', { email });
    return response.ok;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function exchangeMagicCodeForJwt(email: string, magicCode: string) {
  try {
    const response = await makePostRequest('/auth/exchange-magic-code-for-jwt', { email, magicCode });
    if (!response.ok) {
      return null;
    }
    const { token } = await response.json();
    return token;
  } catch (e) {
    console.error(e);
    return null;
  }
}
