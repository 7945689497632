import { Button } from 'src/components/atoms/Button/Button';

import { NewOrExistingChoice } from './types';

interface NewOrExistingLotPromptProps {
  onOptionSelect: (choice: NewOrExistingChoice) => void;
  onRequestCancel: () => void;
}

export const NewOrExistingLotPrompt: React.FC<NewOrExistingLotPromptProps> = ({ onOptionSelect, onRequestCancel }) => {
  return (
    <div className="space-content vertical">
      <h1>New or Existing?</h1>
      <p>Would you like to add a new or existing lot to this activity record?</p>
      <Button className="creative stretch" onClick={() => onOptionSelect('create-new')}>
        New
      </Button>
      <Button className="stretch" onClick={() => onOptionSelect('select-existing')}>
        Existing
      </Button>
      <Button className="stretch secondary" onClick={onRequestCancel}>
        Go Back
      </Button>
    </div>
  );
};
