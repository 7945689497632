import { useNavigate } from 'react-router-dom';
import { PageChrome } from 'src/components/atoms/PageChrome/PageChrome';
import { UI_ROUTES } from 'src/routes';

import { ShipmentEntry } from '../organisms/ShipmentEntry/ShipmentEntry';

export const ShipmentEntryPage = () => {
  const navigate = useNavigate();
  return (
    <PageChrome title="Record Shipment">
      <div className="white-bg light grow pad-content space-content vertical">
        <ShipmentEntry onFinish={() => navigate(UI_ROUTES.home)} />
      </div>
    </PageChrome>
  );
};
