import { useLiveQuery } from 'dexie-react-hooks';
import { db } from 'src/modules/storage/db';
import { Entity } from 'src/types/tnt-docs';

export const EntityList: React.FC = () => {
  const entities = useLiveQuery(() => db.entities.toArray());
  return (
    <div className="EntityListPage">
      <ul>
        {entities?.map((entity) => (
          <EntityListItem key={entity.id} entity={entity} />
        ))}
      </ul>
    </div>
  );
};

const EntityListItem: React.FC<{ entity: Entity }> = ({ entity }) => {
  return (
    <li className="EntityListItem">
      <span>{entity.name}</span>
      <span>{entity.phone}</span>
    </li>
  );
};
