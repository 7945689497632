import { useState } from 'react';
import { Button } from 'src/components/atoms/Button/Button';
import { NumberInput } from 'src/components/atoms/NumberInput/NumberInput';
import { TextInput } from 'src/components/atoms/TextInput/TextInput';
import { useGeolocation } from 'src/modules/geolocation';
import { db } from 'src/modules/storage/db';
import { RegisteredLocation } from 'src/types/tnt-docs';
import { v4 as uuidv4 } from 'uuid';

export interface LocationCreatorProps {
  onLocationCreated?: (location: RegisteredLocation) => void;
  onRequestCancel?: () => void;
}

export const LocationCreator: React.FC<LocationCreatorProps> = ({ onLocationCreated, onRequestCancel }) => {
  const { position: currentPosition } = useGeolocation();
  const [name, setName] = useState('');
  const [latitude, setLatitude] = useState<number>();
  const [longitude, setLongitude] = useState<number>();

  const inputValid = typeof latitude === 'number' && typeof longitude === 'number' && name;

  const handleSubmit = async () => {
    if (!inputValid) return;
    const location: RegisteredLocation = {
      coordinates: {
        latitude: latitude,
        longitude: longitude,
      },
      id: uuidv4(),
      name,
    };
    await db.locations.add(location);
    onLocationCreated?.(location);
  };

  return (
    <div className="LocationCreator form">
      <h1>Register Location</h1>
      <TextInput label="Name" name="name" value={name} onChange={setName} />
      <NumberInput label="Latitude" name="latitude" value={latitude} onChange={setLatitude} />
      <NumberInput label="Longitude" name="longitude" value={longitude} onChange={setLongitude} />
      <Button
        className="secondary stretch"
        disabled={!currentPosition}
        onClick={() => {
          if (!currentPosition) return;
          setLatitude(currentPosition.latitude);
          setLongitude(currentPosition.longitude);
        }}
      >
        Use Current Location
      </Button>
      <Button className="creative stretch" disabled={!inputValid} onClick={handleSubmit}>
        Save Location
      </Button>
      {onRequestCancel && (
        <Button className="secondary stretch" onClick={onRequestCancel}>
          Cancel
        </Button>
      )}
    </div>
  );
};
