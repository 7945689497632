import { Button } from 'src/components/atoms/Button/Button';
import { Base64Image } from 'src/types/images';

import './ImageList.css';

export interface ImageListProps {
  images: Base64Image[];
  onRemove: (imageIdx: number) => void;
}

export const ImageList = ({ images, onRemove }: ImageListProps) => {
  return (
    <div className="density-compact space-content vertical ImageList">
      {images.map((image, idx) => (
        <ImageListItem key={idx} image={image} onRequestRemove={() => onRemove(idx)} onRequestView={() => {}} />
      ))}
    </div>
  );
};

interface ImageListItemProps {
  image: Base64Image;
  onRequestRemove: () => void;
  onRequestView: () => void;
}

const ImageListItem: React.FC<ImageListItemProps> = ({ image, onRequestRemove, onRequestView }) => {
  return (
    <div className="ImageListItem">
      <div className="ImageListItem_image-container" onClick={onRequestView}>
        <img src={image.data} alt="Capture" />
      </div>
      <div className="vertical ImageListItem_actions">
        <Button className="destructive stretch" onClick={onRequestRemove}>
          Remove
        </Button>
      </div>
    </div>
  );
};
