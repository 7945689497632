import { useState } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { Button } from 'src/components/atoms/Button/Button';
import { Card } from 'src/components/atoms/Card/Card';
import { TextInput } from 'src/components/atoms/TextInput/TextInput';
import { db } from 'src/modules/storage/db';
import { Entity } from 'src/types/tnt-docs';

import { EntityCreator } from '../EntityCreator/EntityCreator';

import './EntitySelector.css';

export interface EntitySelectorProps {
  /**
   * Specify to override the default term for an entity.
   * @default 'Entity'
   */
  entityTerm?: string;
  onEntitySelected: (entity: Entity) => void;
  onRequestCancel: () => void;
}

export const EntitySelector: React.FC<EntitySelectorProps> = ({
  entityTerm = 'Entity',
  onEntitySelected,
  onRequestCancel,
}) => {
  const [creatingEntity, setCreatingEntity] = useState<boolean>(false);
  const [filterByNameInputValue, setFilterByNameInputValue] = useState<string>('');

  const filterByNameRegex = new RegExp(filterByNameInputValue, 'i');
  const entities = useLiveQuery(
    () =>
      db.entities
        .filter((entity) => filterByNameRegex.test(entity.name))
        .limit(20)
        .sortBy('name'),
    [filterByNameInputValue],
  );

  if (creatingEntity) {
    return <EntityCreator onEntityCreated={onEntitySelected} onRequestCancel={() => setCreatingEntity(false)} />;
  }

  return (
    <div className="space-content vertical">
      <h1>Select {entityTerm}</h1>
      {entities && entities.length > 0 && (
        <>
          <TextInput label="Filter By Name" onChange={setFilterByNameInputValue} value={filterByNameInputValue} />
          <div>
            <div className="horizontal justify-space-between EntitySelector_EntityList_header">
              <span className="font-size-small">Name</span>
              <span className="font-size-small">Phone Ends In</span>
            </div>
            <ul className="density-compact space-content vertical EntitySelector_EntityList">
              {entities.map((entity) => (
                <EntityListItem key={entity.id} entity={entity} onClick={() => onEntitySelected(entity)} />
              ))}
            </ul>
          </div>
        </>
      )}
      <Button className="secondary stretch" onClick={() => setCreatingEntity(true)}>
        {'Create New ' + entityTerm}
      </Button>
      <Button className="stretch" onClick={onRequestCancel}>
        Go Back
      </Button>
    </div>
  );
};

interface EntityListItemProps {
  entity: Entity;
  onClick?: () => void;
}

const EntityListItem: React.FC<EntityListItemProps> = ({ entity, onClick }) => {
  return (
    <li className="EntitySelector_EntityList_EntityListItem">
      <Card className="density-compact horizontal justify-space-between pad-content space-content" onClick={onClick}>
        <span>{entity.name}</span>

        <span>{entity.phone.substring(entity.phone.length - 4)}</span>
      </Card>
    </li>
  );
};
