// Edit this to test locally (TBD a better way to do this)
const API_URL = 'https://app.oceanworks.co/api';

/**
 * Makes a POST request to the Oceanworks API.
 * @param path the path to the API endpoint (NOT including any /api prefix)
 * @param data the data to include in the body of the request
 * @returns the response from the server
 * @example const response = await makePostRequest('/auth/trigger-mobile-login-email', { email });
 */
export async function makePostRequest(path: string, data: any) {
  return await fetch(API_URL + path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
