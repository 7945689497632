import { ActivityList } from 'src/components/organisms/ActivityList/ActivityList';
import { RecordActivityPrompt } from 'src/components/organisms/RecordActivityPrompt/RecordActivityPrompt';

import { PageChrome } from '../atoms/PageChrome/PageChrome';

export const HomePage: React.FC = () => {
  return (
    <PageChrome title="Home">
      <RecordActivityPrompt className="disable-top-padding" />
      <ActivityList />
    </PageChrome>
  );
};
