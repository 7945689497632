import { useEffect } from 'react';

import { Logo } from '../Logo/Logo';

import './PageChrome.css';

export interface PageChromeProps {
  children: React.ReactNode;
  title: string;
}

export const PageChrome: React.FC<PageChromeProps> = ({ children, title }) => {
  useEffect(() => {
    document.title = title + ' | Oceanworks T&T';
  }, [title]);

  return (
    <div className="page dark vertical PageChrome">
      <header className="pad-content">
        <Logo variant="full" />
      </header>
      <main className="grow vertical">{children}</main>
    </div>
  );
};
