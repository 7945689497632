import { useState } from 'react';
import { Button } from 'src/components/atoms/Button/Button';
import { TextInput } from 'src/components/atoms/TextInput/TextInput';
import { db } from 'src/modules/storage/db';
import { Entity } from 'src/types/tnt-docs';
import { v4 as uuidv4 } from 'uuid';

export interface EntityCreatorProps {
  onEntityCreated?: (entity: Entity) => void;
  onRequestCancel?: () => void;
}

export const EntityCreator: React.FC<EntityCreatorProps> = ({ onEntityCreated, onRequestCancel }) => {
  const [entity, setEntity] = useState<Entity>(generateEmptyEntity());

  const inputValid = entity.name && entity.phone;

  const handleSubmit = async () => {
    if (!inputValid) return;
    await db.entities.add(entity);
    setEntity(generateEmptyEntity());
    onEntityCreated?.(entity);
  };

  return (
    <div className="EntityCreator form">
      <h1>Register Entity</h1>
      <TextInput label="Name" name="name" value={entity.name} onChange={(name) => setEntity({ ...entity, name })} />
      <TextInput
        label="Phone"
        name="phone"
        value={entity.phone}
        onChange={(phone) => setEntity({ ...entity, phone })}
      />
      {/* TODO: Add type select */}
      <Button className="creative stretch" disabled={!inputValid} onClick={handleSubmit}>
        Register
      </Button>
      {onRequestCancel && (
        <Button className="secondary stretch" onClick={onRequestCancel}>
          Cancel
        </Button>
      )}
    </div>
  );
};

const generateEmptyEntity = (): Entity => ({
  id: uuidv4(),
  name: '',
  phone: '',
});
