import './InputWrapper.css';

export interface InputWrapperProps {
  children: React.ReactNode;
  label: string;
}

export const InputWrapper: React.FC<InputWrapperProps> = ({ children, label }) => {
  return (
    <div className="InputWrapper">
      <label>{label}</label>
      {children}
    </div>
  );
};
