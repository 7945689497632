import { Select, SelectOption, SelectProps } from 'src/components/atoms/Select/Select';
import { MaterialCode } from 'src/types/material-codes';

export type MaterialCodeSelectProps = Omit<SelectProps<MaterialCode>, 'label' | 'options'>;

const OPTIONS: SelectOption<MaterialCode>[] = [
  { label: 'Glass', value: 'GLASS' },
  { label: 'HDPE', value: 'HDPE' },
  { label: 'LDPE', value: 'LDPE' },
  { label: 'LLDPE', value: 'LLDPE' },
  { label: 'Metal', value: 'METAL' },
  { label: 'Mixed ', value: 'MIXED-PE' },
  { label: 'Mixed Plastic', value: 'MIXED-PLASTIC' },
  { label: 'Mixed Waste', value: 'MIXED-WASTE' },
  { label: 'Nylon', value: 'NYLON' },
  { label: 'Organics', value: 'ORGANICS' },
  { label: 'Paper', value: 'PAPER' },
  { label: 'PET', value: 'PET' },
  { label: 'PP', value: 'PP' },
  { label: 'Rubber', value: 'RUBBER' },
];

export const MaterialCodeSelect: React.FC<MaterialCodeSelectProps> = (props) => {
  return <Select<MaterialCode> label="Material Type" options={OPTIONS} {...props} />;
};
