import { useState } from 'react';
import { Button } from 'src/components/atoms/Button/Button';
import { MaterialCodeSelect } from 'src/components/organisms/MaterialCodeSelect/MaterialCodeSelect';
import { MaterialFormSelect } from 'src/components/organisms/MaterialFormSelect/MaterialFormSelect';
import { isLot, Lot } from 'src/types/tnt-docs';

export interface LotCreatorProps {
  onRequestCancel: () => void;
  onSubmit: (lot: Lot) => void;
}

const EMPTY_NEW_LOT: Partial<Lot> = {
  uploadStatus: 'pending',
};

export const LotCreator = ({ onRequestCancel, onSubmit }: LotCreatorProps) => {
  const [lot, setLot] = useState<Partial<Lot>>(EMPTY_NEW_LOT);
  const maybeLot: Partial<Lot> = {
    ...lot,
    // TODO: Replace "ACME" with the actual company lot prefix
    number: lot.number || 'ACME-' + lot.materialCode + '-' + new Date().toISOString(),
  };

  const handleSubmit = () => {
    if (!isLot(maybeLot)) return;
    onSubmit(maybeLot);
  };

  return (
    <div className="space-content vertical">
      <h1>Create New Lot</h1>
      <MaterialCodeSelect
        clearable
        onChange={(materialCode) => {
          setLot({
            ...lot,
            materialCode,
          });
        }}
        value={lot.materialCode}
      />
      <MaterialFormSelect
        clearable
        onChange={(materialForm) => {
          setLot({
            ...lot,
            materialForm,
          });
        }}
        value={lot.materialForm}
      />
      {/* TODO: Add physical properties */}
      <Button className="creative stretch" disabled={!isLot(maybeLot)} onClick={handleSubmit}>
        Create Lot
      </Button>
      <Button className="stretch secondary" onClick={onRequestCancel}>
        Go Back
      </Button>
    </div>
  );
};
