import { useEffect, useState } from 'react';

import { InputWrapper, InputWrapperProps } from '../InputWrapper/InputWrapper';

interface NumberInputProps extends Omit<InputWrapperProps, 'children'> {
  onChange: (value: number | undefined) => void;
  name: string;
  value: number | undefined;
}

export const NumberInput: React.FC<NumberInputProps> = ({ label, onChange, value, ...rest }) => {
  const [textValue, setTextValue] = useState<string>(value?.toString() || '');

  // Update our state whenever the value prop changes
  useEffect(() => {
    setTextValue(value?.toString() || '');
  }, [value]);

  return (
    <InputWrapper label={label}>
      <input
        type="number"
        onChange={(e) => {
          const newTextValue = e.target.value;
          setTextValue(newTextValue);
          const newValue = Number.parseFloat(newTextValue);
          if (!Number.isNaN(newValue)) {
            onChange(newValue);
          }
        }}
        value={textValue}
        {...rest}
      />
    </InputWrapper>
  );
};
